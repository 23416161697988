<template>
  <ag-grid-vue
    style="width: 100%; height: 400px;"
    class="ag-theme-alpine"
    :gridOptions="gridOptions"
    @grid-ready="onGridReady"
    :columnDefs="columnDefs"
    :rowData="rowData"
    suppressDragLeaveHidesColumns
    suppressMovableColumns
    checkboxSelection="true"
  >
  </ag-grid-vue>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import moment from "moment";

import { toShowAccountingPrice } from "../../../util/accountingPrice";

export default {
  emits: ["selected-assets"],
  components: { AgGridVue },
  data() {
    return {
      //   columnDefs: null,
      gridApi: "sizeColumnsToFit",
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
      columnDefs: [
        {
          headerName: this.$t("count.order"),
          field: "index",
          maxWidth: 110,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("home.fixedas_num"),
          field: "id_fixed_asset",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.as_name"),
          field: "name",
          sortable: true,
          minWidth: 200,
        },
        // {
        //   headerName: this.$t("home.pause_date"),
        //   field: "deactivate_at",
        //   sortable: true,
        //   minWidth: 200,
        //   cellClass: "text-center",
        // },
        {
          headerName: this.$t("home.writeoff_date"),
          field: "write_off_date",
          sortable: true,
          cellClass: "text-center",
          minWidth: 200,
        },
        {
          headerName: this.$t("home.writeoff_price"),
          field: "sold_price",
          sortable: true,
          minWidth: 200,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("home.net_writeoff_price"),
          field: "net_book",
          sortable: true,
          minWidth: 200,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("home.profit_loss"),
          field: "profit_loss",
          sortable: true,
          minWidth: 200,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("home.note"),
          field: "note_write_off",
          sortable: true,
          minWidth: 200,
        },
      ],
      rowData: this.assetData.map((row, index) => {
        return {
          index: index + 1,
          id_fixed_asset: row.id_fixed_asset,
          name: row.asset_name,
          // deactivate_at: this.momentDay(row.deactivate_at),
          write_off_date: this.momentDay(row.write_off_date),
          sold_price: toShowAccountingPrice(row.sold_price),
          net_book: toShowAccountingPrice(row.write_off_remaining_value),
          profit_loss: toShowAccountingPrice(row.profit_loss) || "-",
          note_write_off: row.note_write_off,
        };
      }),
    };
  },
  props: {
    assetData: { default: () => [] },
  },
  methods: {
    momentDay(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    onGridReady() {
      this.gridApi.sizeColumnsToFit();
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
