<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <template v-if="canUseCustom3">
      <b-modal
        class="p-2"
        id="replace-modal"
        hide-header-close
        hide-header
        hide-footer
      >
        <b-row align-h="end">
          <button
            class="close_modal text-light border-none p-0 mx-2 mb-1"
            @click="$bvModal.hide('replace-modal')"
          >
            <b-icon-x scale="1.5" />
          </button>
        </b-row>
        <h4 class="text-center pb-3">
          {{ $t("subasset.writeoff_steps_header") }}
        </h4>
        <div class="mx-auto" style="width: fit-content;">
          <div
            class="procedure-step"
            v-for="(step, index) in $t('subasset.writeoff_steps')"
            :key="index"
          >
            <div class="number">
              <p class="m-0">{{ index + 1 }}</p>
            </div>
            <p>{{ step }}</p>
            <u v-if="index === 0" class="download" @click="onExport"
              ><nobr>{{ $t("asset.here") }}</nobr></u
            >
          </div>
        </div>
        <b-row align-h="center"
          ><button class="p-2 replace-btn" @click="$refs.fileInput.click()">
            <div class="d-flex align-items-center justify-content-evenly">
              <b-icon-download font-scale="1.5" style="margin-right: 5px" />
              {{ $t("subasset.import_writeoff") }}
            </div>
          </button>
          <input
            id="fusk"
            type="file"
            ref="fileInput"
            style="display: none;"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @input="onFileChange($event)"
          />
        </b-row>
      </b-modal>
      <button class="p-2 replace-btn" @click="$bvModal.show('replace-modal')">
        <div class="d-flex align-items-center justify-content-evenly">
          <b-icon-download font-scale="1.5" style="margin-right: 5px" />
          {{ $t("subasset.import_writeoff") }}
        </div>
      </button>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { baseUrl } from "@/util/backend";
import { exportUrl } from "@/util/backend";
import { authHeader } from "@/store/actions";
import Loading from "vue-loading-overlay";
import Swal from "sweetalert2";
export default {
  emits: ["show-summary", "uploadfile"],
  components: { Loading },
  data() {
    return {
      isLoading: false,
      id_company: null,
    };
  },
  computed: {
    ...mapGetters({
      canUseCustom3: "canUseCustom3",
    }),
  },

  methods: {
    async onExport() {
      let time = new Date();
      window.location.href =
        "https://static.verscan.com/verscan-v1/Template/Template_for_Write_off.xlsx?" +
        time.valueOf();
    },
    async onFileChange(e) {
      const file = e.target.files[0];
      if (file) {
        this.$emit('uploadfile', file)
        this.$refs.fileInput.value = null
      }
    },
    async loadData() {
      this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.procedure-step {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 5px;
  p {
    margin: 0;
  }
  .number {
    border: 1px solid #007afe;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #007afe;
    margin-right: 5px;
  }
  .download {
    color: #007afe;
    padding-left: 5px;
    cursor: pointer;
  }
}

.replace-btn {
  font-size: 14px;
  margin: 0.1rem;
  width: fit-content;
  height: 45px;
  background: transparent;
  color: #007afe;
  border: solid 2px;
  border-radius: 8px;
}

:deep(.swal2-title) {
  font-weight: 400 !important;
}
</style>
